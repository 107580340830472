






import { Component } from 'vue-property-decorator'

import { toImageProps } from '../../../front/shared/support'
import { ImageProps } from '../../../dsl/atoms/Image'

import { AllowedImageRatio, AllowedImageWidth, ImageData } from '../../../contexts'

import { AbstractModuleUi } from '../../abstract/ui'

import { BrandData, BrandTeaserModule } from '../BrandTeaser.contracts'
import { BrandTeaserModuleVersion, brandTeaserModuleVersionComponentRegistry } from '../BrandTeaser.config'
import { AsyncComponent } from 'vue'
import { logger } from '../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<BrandTeaserModuleUi>({
  name: 'BrandTeaserModuleUi'
})
export class BrandTeaserModuleUi extends AbstractModuleUi<BrandTeaserModule> {
  protected versionEnum = BrandTeaserModuleVersion
  /**
   * Determines active slide index.
   */
  public activeSlideIndex = 0

  /**
   * Render version component
   */
  public get component (): AsyncComponent | undefined {
    const component = brandTeaserModuleVersionComponentRegistry[this.brandTeaserVersion]

    if (typeof component === 'undefined') {
      logger(
        `BrandTeaserModule.ui.component(): There's no Vue component associated with the [${this.data.version}] BrandTeaserVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Set default version of BrandTeaser
   */
  public get brandTeaserVersion () {
    if (!this.data.version) {
      return BrandTeaserModuleVersion.Default
    }

    return this.data.version
  }

  /**
   * Get brands
   */
  public get brands (): BrandData[] {
    if (!this.content.brands || this.content.brands.length === 0) {
      return []
    }

    return this.content.brands
  }

  /**
   * Determines slides container width.
   */
  public get slidesContainerWidth (): string {
    const device = this.isMobile() ? 'mobile' : 'desktop'
    const perPage = this.content.perPage[device]
    if (!perPage) {
      return 'auto'
    }

    return `${(100 / perPage)}%`
  }

  /**
   * Determines brand target
   */
  public linkProps (url = '', name = '') {
    return {
      target: url,
      title: name
    }
  }

  /**
   * Determines whether brands defined.
   */
  public hasBrands (): boolean {
    return !!this.content.brands && this.brands.length > 0
  }

  /**
   * Determines image props.
   */
  public getImageProps (image: ImageData): ImageProps {
    if (typeof image === 'undefined') {
      return {
        src: '',
        alt: ''
      }
    }

    return toImageProps(image, AllowedImageRatio.Original, AllowedImageWidth.BrandLogo)
  }

  /**
   * Carousel slide change event handle
   */
  public onSlideChange (activeSlideIndex: number) {
    this.activeSlideIndex = activeSlideIndex
  }
}

export default BrandTeaserModuleUi
